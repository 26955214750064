import { GppMaybe } from '@mui/icons-material';
import {
  Box, Link, Stack, Typography,
} from '@mui/material';
import { useLocation } from '@reach/router';
import { useEffect, useMemo } from 'react';
import useAppDispatch from '../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { useVerifySocialAuthQuery } from '../../../plugins/gatsby-plugin-redux/store/api/auth.api';
import { setToken } from '../../../plugins/gatsby-plugin-redux/store/auth';
import Application from '../../components/app/Application';
import CircularProgress from '../../components/app/CircularProgress';
import Content from '../../components/app/Content';
import useAuthRedirect from '../../hooks/useAuthRedirect';

const Callback = () => {
  useAuthRedirect();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { isLoading, isError, data: token } = useVerifySocialAuthQuery(query.toString());

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      dispatch(setToken(token));
    }
  }, [isLoading, isError, token, dispatch]);

  return (
    <Application>
      <Content>
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          {isLoading && (
          <Stack maxWidth="100%" width={400} textAlign="center" rowGap={1}>
            <CircularProgress center />
            <Typography>Verifying... Please wait...</Typography>
          </Stack>
          )}
          {isError && (
            <Box textAlign="center">
              <Stack flexDirection="row" columnGap={1}>
                <GppMaybe color="error" />
                <Typography>
                  There was an error veriying your identity. Please try again.
                </Typography>
              </Stack>
              <Box marginTop={2}><Link href="/">Home</Link></Box>
            </Box>
          )}
        </Box>
      </Content>
    </Application>
  );
};

export default Callback;

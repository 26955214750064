import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useEffect } from 'react';
import useIsLoggedIn from '../../plugins/gatsby-plugin-session-check/hooks/useIsLoggedIn';

const useAuthRedirect = () => {
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();

  useEffect(() => {
    const redirectTo = new URLSearchParams(location.search).get('redirectTo');
    if (isLoggedIn) {
      void navigate(redirectTo ?? '/', { replace: true });
    }
  }, [isLoggedIn, location.search]);
};

export default useAuthRedirect;
